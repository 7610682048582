import { useCallback, useEffect, useState } from "react";
import "./App.css";
import Button from "@mui/material/Button";

function App() {
  const [data, setData] = useState({});

  const fetchTest = useCallback(async () => {
    const response = await fetch("/reckonertest");
    const data = await response.json();
    setData(data);
  }, []);

  useEffect(() => {
    fetchTest();
  }, []);

  return (
    <>
      <div>{JSON.stringify(data)}</div>
      <br />
      <br />
      <Button
        onClick={() => {
          fetchTest();
        }}
      >
        Refetch
      </Button>
    </>
  );
}

export default App;
